.tile-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  align-items: start;
  height: 100%;
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .tile-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .tile-wrapper {
    display: grid;
    grid-template-columns: 3fr 4fr 3fr;
    height: 100%;
  }
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;