.grid-header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 10px;
}

.grid-header h1 {
  margin: 0;
  color: #3f3f3f;
}

.grid-container {
  height: 100%;
  padding: 10px;
}

.grid-filters {
  padding: 10px 10px;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;