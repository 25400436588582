@import "~antd/dist/antd.less";
@import url("variables.css");
@import url("header.css");
@import url("content.css");
@import url("sidebar.css");
@import url("grid.css");
@import url("carpicker.css");
@import url("utilities.css");
@import url("vehicle.css");
@import url("forms.css");
@import url("reports.css");
@import url("dashboard.css");

@light-grey: #bcbcbc;

//.ant-layout-sider-trigger {
//  margin-bottom: 80px;
//}

.notification-table {
  td {
    padding: 5px;
  }
}

a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #f7f9fc;
}

.table-striped-rows thead {
  background-color: #f7f9fc;
}

.muted {
  color: #858484;
}

.sort-paginate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
}

.service-task-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  //background-color: #3f3f3f;
  padding: 5px;
  border-bottom: 1px solid @light-grey
}

.vehicle-cell {
  p {
    margin-bottom: 0;
  }
}

.vehicle-assignment-view-header {
  display: flex;
  justify-content: space-between;
}

.vehicle-assignment-view-header-item {
  display: flex;
}

.service-view-table {
  width: 100%;

  tr {
    background: white;
    border-bottom: 1px solid #edf1f2;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:first-child {
    td {
      padding-top: 0;
    }
  }

  td {
    padding: 16px 16px 8px 8px;
  }
}

.home-box-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  div{
    align-self: center;
  }
}


.ant-form.login-form {
  background-color: #fff;
  border-radius: 0.3125rem;
  border: solid 1px transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 2rem;
  width: 28rem;
}

.login-form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}

.show-pointer {
  cursor: pointer;
}

.entity-header {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 70px;
  padding: 20px;

  h2 {
    padding: 0;
    margin: 0;
  }

}

.form-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.section-title {
  span {
    color: var(--grey);
  }
}


.card-title {
  .ant-badge-count {
    margin-left: 5px;
    background-color: #636f73;
    color: white;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
  }
}

.section-title-bis {

  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.5em;

  .anticon {
    color: var(--grey);
    margin-right: 5px;
  }

}

.ant-card-bordered {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .04), 0 2px 5px 0 rgba(0, 0, 0, .06), 0 0 0 1px rgba(0, 0, 0, .03);
  border-radius: 6px;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.comment {
  display: flex;
}

.section-container {

  .section-heading {
    border-bottom: 1px solid grey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
  }


  background: white;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .04), 0 2px 5px 0 rgba(0, 0, 0, .06), 0 0 0 1px rgba(0, 0, 0, .03);
  border-radius: 6px;
}

.entity-header .entity-header__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.entity-header__buttons .ant-select-selection--single {
  height: auto;
}

.entity-header__buttons .status {
  overflow: inherit;
}

h1 {
  font-size: 2rem;
}

h1 {
  font-size: 1.5rem;
}

.ant-menu-submenu > .ant-menu {
  max-height: 100vh;
  overflow: auto;
}

.vehicle-info-window {

    .details {
      display:none;
        }
}

.vehicle-info-window:hover {


    .details {
    display: initial;

    }
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;