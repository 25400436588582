/* ==========================================================================
   VEHICLE HEADER
   ========================================================================== */

.vehicle-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid #b6c1c4;
}

.vehicle-header h1 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

.vehicle-header h2 {
  font-size: 0.8rem;
  color: #999999;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.vehicle-info-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* ==========================================================================
   VEHICLE BODY
   ========================================================================== */

.vehicle-body {
  /*background-color: #0d47a1;*/
  min-height: 1000px;
}

.vehicle-side-menu {
  /*background-color: red;*/
  /*width: 230px;*/
  height: 100%;
  padding: 20px 10px 20px 20px;
}

.vehicle-body-content {
  width: 100%;
  padding: 20px 20px 20px 10px;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;