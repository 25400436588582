/* ==========================================================================
   REPORTS
   ========================================================================== */

.table-container {
  width: 100%;
  overflow-y: auto;
  padding: 10px;
}

.table-reports {
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;