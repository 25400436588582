.small-form-container {
  align-items: center;
  display: flex;
  height: 100vh;
  padding-top: 100px;
  flex-direction: column;
}

.medium-form-container {
  align-items: center;
  display: flex;
  height: 100vh;
  padding-top: 20px;
  flex-direction: column;
}

.login-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
}

.medium-form-buttons {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;