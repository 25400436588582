/* ==========================================================================
   header.css
   ========================================================================== */

.ant-layout-header {
  color: #fff;
  padding: 0 20px !important;

  background-color: #0d47a1;
}

.ant-layout-header .user {
  text-align: right;
}

.ant-avatar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.user .ant-avatar > img {
  width: 100%;
}

.ant-layout-header .user strong {
  margin-right: 0.5rem;
}

.ant-layout-header .user .ant-btn {
  border: none;
  color: #fff;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  position: relative;
}

.ant-layout-header .user .ant-btn .anticon {
  position: absolute;
  top: 0.75rem;
  right: 0;
}

.ant-layout-header .user .ant-btn .anticon + span {
  margin-left: 0;
}

.ant-layout-header .user .ant-btn .anticon:hover {
  color: #fff;
}

.logo {
  margin-right: 2rem;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;