/* ==========================================================================
   sidebar.css
   ========================================================================== */

.sidebar {
  overflow-y: auto;
  padding-top: 33px;
  position: fixed !important;
  height: 100% !important;
  z-index: 2;
}

.sidebar + .main-content {
  margin-left: 220px;
  transition: margin-left 1s 1s 1s;
}

.sidebar.ant-layout-sider-collapsed + .main-content {
  margin-left: 80px;
  transition: margin-left 1s 1s 1s;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;