:root {
}

.car-picker__modal {
  min-width: 300px;
}

.car-picker {
  margin-bottom: 2rem;
}

.car-picker__filter {
  margin-bottom: 10px;
  width: 100px;
}

.car-picker__button {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7e0859+0,600049+100 */
  background: var(---bacground-gradient-1); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    var(---bacground-gradient-1) 0%,
    var(--background-gradient-2) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    var(---bacground-gradient-1) 0%,
    var(--background-gradient-2) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    var(---bacground-gradient-1) 0%,
    var(--background-gradient-2) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e0859', endColorstr='#600049',GradientType=1 ); /* IE6-9 */
  border: 1px solid #dcdcdc;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  padding: 1rem;
  font-size: 1.2rem;
  margin-top: 0;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.13);
  cursor: pointer;
  height: 70px;
}

.car-picker__button.selected {
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.car-picker__button.selected {
  display: flex;
  justify-content: space-between;
  background: #fff;
  color: #3f3f3f;
}

.car-picker__button-text {
  font-size: 0.9375rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin: 0;
}
@media screen and (min-width: 380px) {
  .car-picker__button-text {
    font-size: 1.125rem;
  }
}

.car-picker__header {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-picker-option {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0.875rem 0.75rem;
  margin: 0 0 8px 0;
  background: #fff;
  text-align: center;
  font-size: 0.9375rem;
}

.picker-car-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #3f3f3f;
}

.picker-car-label img {
  margin-right: 10px;
  width: 50px;
}

.picker-car-label p {
  font-size: 1rem;
  line-height: 1.125rem;
}

.picker-car-label p:last-child {
  color: var(--brand-primary-color);
}

.car-picker__inner-wrapper {
  height: 500px;
  overflow-y: auto;
}

/* ==========================================================================
   modal-car-picker.css
   ========================================================================== */

.car-picker__modal {
  min-width: 300px;
}

.car-picker__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-picker__header {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.car-picker__header h2 {
  color: var(--brand-primary-color);
  font-size: 1.125rem;
  margin-bottom: 0;
}

.car-picker-option {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0.875rem 0.75rem;
  margin: 0 0 8px 0;
  background: #fff;
  text-align: center;
  font-size: 0.9375rem;
  cursor: pointer;
}

.car-picker-option:active {
  background-color: var(--brand-primary-color);
  color: #fff;
}

.picker-car-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #3f3f3f;
}

.picker-car-label p {
  margin-bottom: 0;
}

.picker-car-label img {
  margin-right: 10px;
  width: 50px;
}

.car-picker__inner-wrapper {
  height: 500px;
  overflow-y: auto;
}

.modal-cancel {
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 300;
  padding: 1rem 0 0;
}

.modal-cancel__title {
  margin-bottom: 1rem;
  font-weight: 500;
}

.cancel-option {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.875rem 0.75rem;
  margin: 1rem 0 0;
  background: #fff;
  text-align: center;
  font-size: 1rem;
}

.cancel-option:active,
.cancel-option:focus {
  background-color: #999;
  color: #fff;
}

.modal-cancel .btn-clean {
  display: block;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  color: #888;
  margin: 0 auto;
}

.modal-cancel .btn-secondary {
  width: 100%;
}

.car-picker__modal-close img,
.car-picker__modal-back img,
.modify-amount__cancel img {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;