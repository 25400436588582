:root {
  --blue: #0066b3;
  --cyan: #0098b0;
  --cyan-light: #01aec9;
  --green: #3fbc94;
  --brand-primary-color: var(--cyan);
  --brand-primary-color-light: var(--cyan-light);
  --brand-secondary-color: var(--green);
  ---bacground-gradient-1: var(--blue);
  --background-gradient-2: var(--cyan);

  --dark-grey: #454545;
  --grey: #808080;
  --light-grey: #bcbcbc;
  --blue-violet: #2c3357;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;