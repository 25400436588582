/* ==========================================================================
   content.css
   ========================================================================== */

.body {
  margin-top: 64px;
}

.content-block {
  margin-bottom: 1.5rem;
}

.main-section {
  margin: 1rem;
}

.section-block {
  margin: 1rem;
  padding: 1rem;
}

.help h2 {
  font-style: normal;
  color: var(--dark-grey);
  margin-top: 2em;
  margin-left: -1em;
}

.help h3 {
  font-size: 1em;

  color: var(--cyan);
}

.help {
  margin-left: 3em;
}

@primary-color: #018da2;@layout-header-background: #062c50;@font-size-base: 15px;